$(document).ready(function () {


  // drop open and hide when click outside
  const
    links = ".dropdown-link-js",
    innerElements = ".dropdown-js";

  $(document).on("click", links, function (e) {
    if ($(this).hasClass("active")) {
      $(links).filter('a[href="' + this.hash + '"]').removeClass("active");
      $(innerElements).filter(this.hash).removeClass("open");
    } else {
      $(links).removeClass("active").filter('a[href="' + this.hash + '"]').addClass("active");
      $(innerElements).removeClass("open").filter(this.hash).addClass("open");
      $(links).add(innerElements).off();
    }
    e.preventDefault();
  }).on("mouseup touchstart keyup", function (e) {
    if ((!$(links).add(innerElements).is(e.target) && $(links).add(innerElements).has(e.target).length === 0) || e.keyCode === 27) {
      $(links).removeClass("active");
      $(innerElements).removeClass("open");
    }
  });


  // slider
  var swiper = new Swiper(".reviews-js", { // jshint ignore:line
    autoHeight: true,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });


  // accordion
  const accordion = function name(links, panels) {
    $(document).on("click", links, function (e) {
      if (!$(this).hasClass("active")) {
        $(links).filter('a[href="' + this.hash + '"]').addClass("active");
        $(panels).filter(this.hash).slideDown();
      } else {
        $(links).filter('a[href="' + this.hash + '"]').removeClass("active");
        $(panels).filter(this.hash).slideUp();
      }
      e.preventDefault();
    });
  };

  accordion(".accordion-links-js", ".accordion-panels-js");


});
